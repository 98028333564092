import React, { Component } from 'react'
import xcala_logo from "../../../Assets/logo.png"
import "./Navmenu.css"

export default class Navmenu extends Component {
  render() {
    return (
      <div className='Navmenu'>
        <img src={xcala_logo}/>
      </div>
    )
  }
}
