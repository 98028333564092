import React, { Component } from 'react'
import IconTextBlock from '../../App_Components/IconTextBlock/IconTextBlock'
import DataInfoBlock from '../../App_Components/DataInfoBlock/DataInfoBlock'
import "./HomeBody.css"
import screen_icon from "../../../Assets/screen_icon.png"
import marketing_icon from "../../../Assets/online_marketing.png"
import social_icon from "../../../Assets/social_media.png"
import logistic_icon from "../../../Assets/logistic.png"
import HorizontalScrollableSection from '../../App_Components/HorizontalScrollableSection/HorizontalScrollableSection'
import InfoCard from '../../App_Components/InfoCard/InfoCard'
import eSports from "../../../Assets/esports.jpg"
import socialSelling from "../../../Assets/social_selling.JPG"
import eFashion from "../../../Assets/efashion.JPG"
import saas from "../../../Assets/saas.JPG"
import niche from "../../../Assets/niche.JPG"
import plus from "../../../Assets/plus.JPG"
import FooterCard from '../../App_Components/FooterCard/FooterCard'
import MyBrooSection from '../../MyBrooSection/MyBrooSection'







export default class HomeBody extends Component {
  render() {
    return (
      <div>
        <h2 style={{marginTop:"50px", marginBottom:"30px"}} >Excellence in Execution</h2>
        <IconTextBlock icon={screen_icon} subtitle={'Our platforms'} content={"All our platforms are In-House Developments and optimized for maximize conversions with usability, data tracking and fast load techniques"} />
        <IconTextBlock icon={marketing_icon} subtitle={'Digital marketing'} content={"Optimized to reach organic traffic. High tech developments in online advertising and social selling channels"} />
        <IconTextBlock icon={social_icon} subtitle={'Social media'} content={"With top celebrities as collaborators whose enhance our own social media channels targeting hundreds of thousands followers"} />
        <IconTextBlock icon={logistic_icon} subtitle={'Logistic infrasctructure'} content={"With the same efficiency we operate the technical and commercial side, we run the operative side of our projects"} />
        {/* <MyBrooSection /> */}
        <div style={{backgroundColor:"#1f1a33",}}>
          <h2 style={{paddingTop:"50px", marginBottom:"30px", color:"white"}} >We are</h2>
          <p className='HomeBody_p'>
            Entrepreneurs <span> building and scaling tech based business </span> to solving human problems in Latin America
          </p>
          <p className='HomeBody_p_sub'>
            We are in different market sectors
          </p>
          <HorizontalScrollableSection children_qty={6}>
            <InfoCard title={"E-Sports"} img_src={eSports}/>
            <InfoCard title={"Social Selling"} img_src={socialSelling}/>
            <InfoCard title={"E-Com Fashion"} img_src={eFashion}/>
            <InfoCard title={"SaaS"} img_src={saas}/>
            <InfoCard title={"E-Tailing"} img_src={niche}/>
            <InfoCard title={""} img_src={plus}/>
          </HorizontalScrollableSection>
          <DataInfoBlock />
        </div>



        <div style={{backgroundColor:"#384666",}}>
          <FooterCard title={"Xcala SAS"} listArray={["901473892", "KR21 #44 30", "Bogotá, Colombia"]}/>
          <FooterCard title={"Contact us"} listArray={["+573197864523","info@xcala.co","Contact information"]}/>
          {/* <FooterCard title={"Links"} listArray={["Privacy Policy", "Terms of service"]}/> */}
          <FooterCard title={"Links"} listArray={["Privacy Policy"]}/>
          
        </div>

        

      </div>
    )
  }
}
