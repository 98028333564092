import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div>
      <h1>Política de privacidad</h1>
      <p>
        XCALA SAS, junto a sus aliados tecnológicos, comerciales y estratégicos autorizados, cesionarios, licenciatarios, filiales y/o subordinadas, informa, conforme a la Ley 1581 de 2012, su Decreto Reglamentario 1377 de 2013 y la Ley 1266 de 2008, que los datos personales suministrados en actividades, dinámicas u operaciones con la Compañía serán tratados conforme a su política de Protección de Datos Personales. Esto incluye el uso y mantenimiento de medidas de seguridad técnicas, físicas y administrativas para prevenir el acceso no autorizado a dichos datos.
      </p>
      <p>
        Los datos personales y de contacto serán recolectados, compilados, almacenados, depurados, usados, intercambiados, circulados, actualizados, suprimidos, complementados, corregidos y adicionados con información propia y/o de terceros autorizados. Estos procesos se realizarán con fines administrativos, comerciales, de mercadeo, para el envío de información sobre programas y servicios, campañas, promociones, concursos de carácter comercial y publicitario, invitaciones a eventos, evaluación de la calidad del servicio, estudios de mercado, comercialización de servicios y productos a través de distintos canales de información, administración para incrementar la confiabilidad y contactabilidad de los canales de comunicación, cumplimiento de obligaciones contractuales y/o legales con aliados, clientes, empleados, proveedores, autoridades judiciales y administrativas, y transferencia de información a terceros países bajo las condiciones establecidas en la ley.
      </p>
      <p>
        Se realizará consulta, verificación o reporte de hábitos de pago, comportamiento e historial crediticio, financiero, comercial y divulgación ante aliados, centrales de riesgo, bases de datos u otros operadores de información o terceros que presten servicios a XCALA SAS.
      </p>
      <p>
        Invitamos a conocer la Política de Protección de Datos Personales en la página web www.xcala.co, la cual incluye procedimientos para que los titulares de datos personales ejerzan sus derechos a conocer, actualizar, rectificar y suprimir la información, solicitar prueba de la autorización y revocar la autorización.
      </p>
      <p>
        Esta Política de privacidad describe cómo se recopila, utiliza y comparte su información personal al visitar o realizar una compra en www.xcala.co (el "Sitio").
      </p>
      <h2>Información Personal que Recopilamos</h2>
      <p>
        Al visitar el Sitio, automáticamente recopilamos cierta información sobre su dispositivo, incluyendo información sobre su navegador web, dirección IP, zona horaria y algunas de las cookies instaladas en su dispositivo. A medida que navega por el Sitio, recopilamos información sobre las páginas web o productos que ve, qué sitios web o términos de búsqueda lo remitieron al Sitio e información sobre cómo interactúa con el Sitio. Nos referimos a esta información automáticamente recopilada como "Información del dispositivo".
      </p>
      <p>
        Recopilamos Información del dispositivo usando tecnologías como cookies, archivos de registro y balizas web, etiquetas y píxeles. Las cookies son archivos de datos que se colocan en su dispositivo y a menudo incluyen un identificador único anónimo. Para más información sobre cookies y cómo deshabilitarlas, visite http://www.allaboutcookies.org.
      </p>
      <p>
        Cuando realiza una compra o intenta realizar una compra a través del Sitio, recopilamos cierta información sobre usted, como su nombre, dirección de facturación, dirección de envío, información de pago, dirección de correo electrónico y número de teléfono. Nos referimos a esta información como "Información del pedido".
      </p>
      <p>
        Cuando hablamos de "Información personal" en esta Política de privacidad, nos referimos tanto a la Información del dispositivo como a la Información del pedido.
      </p>
      <h2>Cómo Usamos Tu Información Personal</h2>
      <p>
        Utilizamos la Información del pedido que recopilamos para cumplir con los pedidos realizados a través del Sitio (incluyendo el procesamiento de su información de pago, organización del envío y suministro de facturas y/o confirmaciones de pedidos). Además, utilizamos esta Información del pedido para comunicarnos con usted, revisar nuestros pedidos en busca de riesgo potencial o fraude y, en línea con sus preferencias, proporcionarle información o publicidad relacionada con nuestros productos o servicios.
      </p>
      <p>
        Utilizamos la Información del dispositivo que recopilamos para ayudarnos a detectar posibles riesgos y fraudes (en particular, su dirección IP) y, en general, para mejorar y optimizar nuestro sitio (por ejemplo, generando análisis sobre cómo nuestros clientes navegan e interactúan con el Sitio, y para evaluar el éxito de nuestras campañas de marketing y publicidad).
      </p>
      <h2>Compartir Su Información Personal</h2>
      <p>
        Compartimos su Información personal con terceros para ayudarnos a usar su Información personal, como se ha descrito anteriormente. Por ejemplo, usamos Shopify para impulsar nuestra tienda en línea, y Google Analytics para entender cómo nuestros clientes usan el Sitio. También podemos compartir su Información personal para cumplir con leyes y regulaciones aplicables, responder a una citación, orden de allanamiento u otra solicitud legal de información, o para proteger nuestros derechos.
      </p>
      <h2>Publicidad Comportamental</h2>
      <p>
        Como se describió anteriormente, utilizamos su Información personal para brindarle anuncios dirigidos o comunicaciones de marketing que creemos pueden ser de su interés.
      </p>
      <h2>No Seguir</h2>
      <p>
        Tenga en cuenta que no alteramos nuestras prácticas de uso y recopilación de datos del Sitio cuando vemos una señal de No rastrear de su navegador.
      </p>
      <h2>Tus Derechos</h2>
      <p>
        Si es residente europeo, tiene derecho a acceder a la información personal que tenemos sobre usted y a solicitar que se corrija, actualice o elimine su información personal. Si desea ejercer este derecho, por favor comuníquese con nosotros.
      </p>
      <p>
        Además, si es residente europeo, notamos que estamos procesando su información para cumplir con contratos que podríamos tener con usted o para perseguir nuestros intereses comerciales legítimos. Además, tenga en cuenta que su información se transferirá fuera de Europa, incluidos Canadá y Estados Unidos.
      </p>
      <h2>Retención de Datos</h2>
      <p>
        Cuando realiza un pedido a través del Sitio, mantendremos la Información de su pedido para nuestros registros a menos y hasta que nos solicite que eliminemos esta información.
      </p>
      <h2>Menores</h2>
      <p>
        El Sitio no está destinado a personas menores de 18 años.
      </p>
      <h2>Cambios</h2>
      <p>
        Podemos actualizar esta política de privacidad de vez en cuando para reflejar, por ejemplo, cambios en nuestras prácticas o por otras razones operativas, legales o reglamentarias.
      </p>
      <h2>Uso del API de WhatsApp</h2>
        <p>
        En XCALA SAS, utilizamos el API de WhatsApp para facilitar la comunicación con nuestros clientes y mejorar nuestra oferta de servicios. Al interactuar con nosotros a través de WhatsApp, es posible que recopilemos información personal como su número de teléfono, nombre y cualquier otro dato que comparta durante la conversación. Esta información se utiliza exclusivamente para proporcionar el servicio solicitado, responder a consultas, procesar pedidos o enviar información relevante. Nos comprometemos a manejar estos datos con el máximo cuidado y seguridad, y a no compartirlos con terceros sin su consentimiento explícito, salvo en los casos en que sea necesario para cumplir con obligaciones legales o contractuales. Para más detalles sobre cómo WhatsApp procesa los datos personales, por favor revise la política de privacidad de WhatsApp en su sitio web oficial.
        </p>

    </div>
  );
}

