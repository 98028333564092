import React, { Component } from 'react'
import Footer from '../../Components/App_Components/Footer/Footer'
import Navmenu from '../../Components/App_Components/Navmenu/Navmenu'
import Header from '../../Components/Home_Components/Header/Header'
import HomeBody from '../../Components/Home_Components/Home_Body/HomeBody'
import './Home.css'

export default class Home extends Component {
  render() {
    return (
        <div className="Home">
            <Header />
            <HomeBody />
        </div>
    )
  }
}
