import React, { Component } from 'react'
import "./DataInfoBlock.css"
import ApexCharts from 'apexcharts'

export default class DataInfoBlock extends Component {

  componentDidMount=()=>{
    var randomizeArray = function (arg) {
        var array = arg.slice();
        var currentIndex = array.length, temporaryValue, randomIndex;
      
        while (0 !== currentIndex) {
      
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
      
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
      
        return array;
      }
      
      // data for the sparklines that appear below header area
      var sparklineData = [35781, 39102, 43174, 32512, 35089, 57820];
      var spark3 = {
        chart: {
          id: 'sparkline3',
          group: 'sparklines',
          type: 'area',
          height: 260,
          sparkline: {
            enabled: true
          },
        },
        stroke: {
          curve: 'straight'
        },
        fill: {
          opacity: 1,
        },
        series: [{
          name: 'New customers',
          data: sparklineData
        }],
        // labels: [...Array(3).keys()].map(n => `2018-09-0${n+1}`),
        labels: [...Array(6).keys()].map(n => `2023-${n+1}`),
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          min: 0
        },
        colors: ['#008FFB'],
        // colors: ['#008FFB'],
        //colors: ['#5564BE'],
        title: {
          text: '2.438.965',
          offsetX: 30,
          style: {
            fontSize: '24px',
            cssClass: 'apexcharts-yaxis-title',
            color:"white"
          }
        },
        subtitle: {
          text: 'Customers served (All platforms)',
          offsetX: 30,
          style: {
            fontSize: '14px',
            cssClass: 'apexcharts-yaxis-title',
            color:"white"

          }
        }
      }

      var chart = new ApexCharts(document.querySelector("#DataInfoBlock"), spark3);
      chart.render();
  }

  render() {
    return (
      <div>
        <div id="DataInfoBlock"></div>
      </div>
    )
  }
}
