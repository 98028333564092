import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { app } from './firebase_init/init';
import Home from './Pages/Home/Home';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsOfService from './Pages/TermsOfService';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* <Route path="/terms-of-service" element={<TermsOfService />} /> */}
          
          {/* Aquí puedes añadir más rutas para otros componentes */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;

