import React, { Component } from 'react'
import "./FooterCard.css"

export default class FooterCard extends Component {
  render() {
    const {title, listArray} = this.props
    return (
      <div className='FooterCard'>
        <h2 className='FooterCard_title'>{title}</h2>

        <div className="FooterCard_list">
            {listArray.map(item=>
                item === "Privacy Policy"? 
                <a style={{color:"white"}} href="/privacy-policy">
                  <div>{item}</div>
                </a>
                :
                item === "Terms of service"? 
                <a style={{color:"white"}} href="/terms-of-service">
                  <div>{item}</div>
                </a>
                :
                <div>{item}</div>
            )}
        </div>
      </div>
    )
  }
}
