import React, { Component } from 'react'
import "./IconTextBlock.css"

export default class IconTextBlock extends Component {
  render() {
    const {icon, subtitle, content} = this.props
    return (
      <div className='IconTextBlock'>
        <img src={icon}/>
        <div>
            <strong>{subtitle}</strong>
            <p>{content}</p>
        </div>
      </div>
    )
  }
}
