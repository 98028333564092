import React, { Component } from 'react'
import "./InfoCard.css"

export default class InfoCard extends Component {
  render() {
    const {title, text, img_src} = this.props
    return (
      <div className='InfoCard'>
        <img className='InfoCard_img' src={img_src}/>
        <div className="InfoCard_title">{title}</div>
      </div>
    )
  }
}
