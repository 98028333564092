import React, { Component } from 'react'
import "./Header.css"
import Header_img from "../../../Assets/xcalalatam.jpg"
import Navmenu from '../../App_Components/Navmenu/Navmenu'

export default class Header extends Component {
  render() {
    return (
      <div className='Header'>
      
        <Navmenu/>

        <span className="Header_img_span">
          <img src={Header_img}/>
          {/* <video controls={true}>
            <source src="https://www.youtube.com/watch?v=UBCmgslD6N8" type="video/mp4"/>
          </video> */}
        </span>

        <div className='Header_title'>
          <h1>
            We solve Latin America problems through network effects
          </h1>
          <p>
            <div className='Header_title_subtitle'>
              Together we are stronger 
            </div>
          </p>
        </div>

        <div className='Header_button_container d-flex justify-content-center align-items-center'>
          <div className='Header_button'>See more</div>
        </div>
      </div>
    )
  }
}
