import React, { Component } from 'react'
import "./HorizontalScrollableSection.css"

export default class HorizontalScrollableSection extends Component {
  render() {
    const {children, children_qty} = this.props
    let centerTabs = false
    
    return (
      <div className='container-fluid'>
        <div className='row'>
            <div className={`col-12 HorizontalScrollableSection d-flex HorizontalScrolling_wrapper ${centerTabs===true?'justify-content-center':''}`}>
                {children}     
            </div>
        </div>
      </div>
    )
  }
}
